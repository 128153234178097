@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');


.arrow {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 4vmax;
    font-weight: 600;
    position: fixed;
    top: 50vh;
}

#toPrev {
    left: 1vw;
}

#toNext {
    right: 1vw;
}

.illustration {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

#illustration {
    margin: auto;
}
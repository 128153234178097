@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    background-color: #efeee9;
}
.App {
    min-height: 100vh;
    padding-top: 80px;
    background-color: #efeee9;
}

body {
    /* white-space: pre-line; */
    min-height: 100%;
    background-color: #efeee9;
}

.Home  {
    background-color: 'black';
    margin-top: -10px;
    background-image: url(/static/media/home-illustration-nov-2021.87fe90fa.png) !important;
    height:100vh;
    width:100vw;
    background-size: cover;
}

@media screen and (max-width: 600px) {
    .Home  {
        background-color: 'black';
        margin-top: -10px;
        background-image: url(/static/media/home-illustration-mobile.1a77b438.png) !important;
        height:100vh;
        width:100vw;
        background-size: cover;
    }
  }
.cardRow {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
}

.logoRow {
    position: relative;
    margin: auto auto 10vh auto;
    width: 100%;
    height: 30vh;
}

.lamaithLogo {
    height: 100%;
    width: 100%;
}

.menuCard {
    width: 100%;
    height: 100%;
    border: 1px solid #333;
    transition: 0.5s;
}

.menuCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.cardImage {
    height: 100%;
    width: 100%;
    transition: 1s;
}

.cardImage:hover {
    opacity: 0.9
}

.navLink {
    height: 15vmax;
    width: 15vmax;
    margin: auto;
    font-size: 1.5vmax;
    position: relative;
    font-family:'Inter', Tahoma, Geneva, Verdana, sans-serif;
    color : #333;
    text-decoration: none !important;
}

.contentHeadingContainer {
    font-family: 'Josefin Sans', sans-serif !important;
    position: relative;
    font-size: 4vmax;
    color: #333;
    border-bottom: 1px solid #D9D9D9;
    width: 95%;
    margin-left: 2.5%;
}

.contentHeading {
    font-size: 1.5vmax;
}

.contentContainer {
    width: 95%;
    margin-top: 5vh;
    margin-bottom: 10vh;
    font-family: 'Roboto', sans-serif;
    margin-left: 2vw;
    margin-right: 2vw;
    background-color: #efeee9;

}

.content {
    font-family: 'Roboto', sans-serif;
}

.subHeading {
    font-family: 'Open Sans', sans-serif;
    font-weight: bolder;
    font-size: 1vmax;
    margin-bottom: 2vh;
}

.section {
    margin-bottom: 5vh;
}



.collectionGrid {
    display: grid;
    grid-template-columns: 20vmax 20vmax 20vmax 20vmax 20vmax;
    word-break: break-word;
}

.collectionBrief {
    height: 20vmax;
    width: 20vmax;
    position: relative;
    border: 1px solid rgb(211, 211, 211);
}

.collectionThumbnail {
    height: 100%;
    width: 100%;
    object-fit: contain;
    transition: 0.3s;
}

.overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(34,34,34,0), rgba(34,34,34,0.7));
    transition: opacity 0.3s linear;
    font-family: 'Josefin Sans', sans-serif;
}

.overlay:hover {
    opacity: 0.9;
}

.overlayInner {
    position: absolute;
    padding: 1.3125rem 2rem;
    bottom: 0;
    left: 0;
    width: 100%;
}

.collectionBriefName {
    font-size: 2vmax;
    font-weight: 600;
    color: white;
    line-height: 1.5rem;
    overflow-wrap: break-word;
    position: absolute;
    bottom: 18px;
    left: 18px;
    font-family: 'Open Sans', sans-serif !important;
}

#descriptionDiv {
    width: 25vw;
    height: 45vw;
}

#descriptionPic {
    width: 100%;
    height: 100%;
}

#photo0div {
    width: 55vw;
    height: 50vw;
    margin-left: -4%;
}

#photo1div {
    margin-top: 5vw;
    height: 70vw;
}

#photo2div {
    width: 55vw;
    height: 80vw;
}

#photo3div {
}

#photo4div {
    margin-top: 10vh;
    border-radius: 50%;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    width: 30vw;
    margin-left: 5vw;
}

#photo4 {
    border-radius: 50%;
}

#photo5div {
    margin-top: 5vw;
    height: 70vw;
}

#photo6div {
    width: 55vw;
    height: 80vw;
}

#photo7div {
}

#photo8div {
}

#photo9div {
}

.photoDiv {
    position: relative;
    border: 1px solid #D9D9D9;
    margin-bottom: 10vh;
}

.photoRow6 {
    display: grid;
    grid-template-columns: 45vw 45vw ;
    word-break: break-word;
}

.photo {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}
#messageDiv {
    max-height: 70vh;
}

#galleryMessage {
    width: 100%;
    height: 100%;
}

#messageAndI1Col{
    width: 45%;
}

.illustrationRow5 {
    display: grid;
    grid-template-columns: 30vw 30vw 30vw;
    word-break: break-word;
}

.illustration {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

#illustration1 {
}

#illustration0div {
}

#illustration1div {
}

#illustration2div {
}

#illustration3div {
    margin-top: 10vh;
    border-radius: 50%;
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg);
}

#illustration3 {
    border-radius: 50%;
}

#illustration4div {
}

#illustration5div {
}

#illustration6div {
}

#illustration7div {
}

#illustration8div {
}

#illustration9div {
}

.illustrationDiv {
    position: relative;
    border: 1px solid #D9D9D9;
    margin-bottom: 10vh;
}

.overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(34,34,34,0), rgba(34,34,34,0.7));
    transition: opacity 0.3s linear;
    font-family: 'Josefin Sans', sans-serif;
}

.overlay:hover {
    opacity: 0.9;
}

.overlayInner {
    position: absolute;
    padding: 1.3125rem 2rem;
    bottom: 0;
    left: 0;
    width: 100%;
}

.illustrationBriefName {
    font-size: 2vmax;
    font-weight: 600;
    color: white;
    line-height: 1.5rem;
    overflow-wrap: break-word;
    position: absolute;
    bottom: 18px;
    left: 18px;
    font-family: 'Open Sans', sans-serif !important;
}
.arrow {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 4vmax;
    font-weight: 600;
    position: fixed;
    top: 50vh;
}

#toPrev {
    left: 1vw;
}

#toNext {
    right: 1vw;
}

.illustration {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

#illustration {
    margin: auto;
}
.diary {
    margin: auto;
    width: 85vw;
    border: 1px solid rgb(185, 185, 185);
    background-color:rgb(252, 252, 252);
}

.entry {
    border-top: 1px solid rgb(185, 185, 185);
    margin: 1vh 1vw 1vh 1vw;
}

.logoContainer {
    margin: auto;
    width: 20vw;
    height: 20vh;
    padding-bottom: 1vh;
    margin-bottom: 2vh;
}

.logo {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.entryTitle {
    margin: auto;
    margin-top: 1vh;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 4vmax;
}

.entryDate {
    margin: auto;
    text-align: center;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
}

p {
    font-family: 'Open Sans', sans-serif;
}

.entryPhotoDiv {
    height: 20vmax;
    width: 20vmax;
    border: 1px solid rgb(185, 185, 185);
}

.entryPhotoCol {
    width: 30%;
}

.entryDescriptionCol {
    max-height: 20vmax;
    margin-left: 21vmax;
    margin-top: -20vmax;
}

.entryPhoto {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.entryBrief {
    border-top: 1px solid rgb(185, 185, 185);
    width: 90%;
    height: 25vmax;
    overflow: hidden;
    margin-top: 2vh;
}

.entryBriefInner {
    width: 100%;
    height: 25vmax;
    margin-top: 2vh;

}

.entryDescriptionRow {
    max-height: 15vmax;
    display: inline-block;
    width: calc(100%);
    font-size: 13px;
    overflow: hidden;
}

.entryDescription {
}

.seeMoreAndDateRow {
    font-weight: bold;

}
.service {
    margin-top: 2vh;
    margin-bottom: 2vh;
    border-bottom: 1px solid rgb(223, 223, 223);
}
.serviceName {
    font-size: 1.2vmax;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}
*, *:before, *:after {
    box-sizing: border-box;
}

label {
    font-family: 'Open Sans', sans-serif;
    font-size: 2vmax;
}

input {
    font-family: 'Open Sans', sans-serif;
    border: none;
    border-bottom: 1px solid #D9D9D9;
    min-width: 100%;
}

input:focus {
    outline: none;
    /*border-bottom: 1px solid rgb(129, 126, 126) !important;*/
    border-bottom: 1px solid  rgb(45, 118, 212);
}

.error {
    font-style: italic;
    color: rgb(172, 20, 20);
    margin-bottom: 1vh;
}

.MyTextInputContainer {
    margin-bottom: 10px;

}

button {
    border: 1px solid #D9D9D9;
    margin-top: 2vh;
    border-radius: 1px;
    outline: none !important;
    color: #333;
    background-color: #FFF;
    transition: 0.2s;
    height: 5vh !important;
}

button:hover {
    cursor: pointer
}

button[type='submit']:hover {
    cursor: pointer;
    border: 1px solid #40A9FF;
    color: #40A9FF;
}


.photoField {
    margin: 1.5vh 0 1.5vh 0;
}

.addButton {
    margin: 2.5vh 0 1.5vh 0;
    border: 1px solid #D9D9D9;
}

.addButton:hover {
    border: 1px solid rgb(45, 118, 212);
    background-color:  rgb(231, 240, 252);
}

.removeButton {
    margin-top: 2vh;
    color: white;
    background-color: rgb(238, 53, 53);
    border: 1px solid rgb(218, 64, 64);;
}

.removeButton:hover {
    background-color: rgb(218, 64, 64);
    border: 1px solid rgb(218, 64, 64);;
}

.deleteButton {
    margin-top: 2vh;
    height: 5vh;
    width: 100%;
    border-radius: 5px;
    color: white;
    background-color: rgb(238, 53, 53);
    border: 1px solid rgb(218, 64, 64);;
}

.deleteButton:hover {
    background-color: rgb(218, 64, 64);
    border: 1px solid rgb(218, 64, 64);;
}

.currentPhotoContainer {
    width: 40vw;
    height: 40vh;
    border: 1px solid #D9D9D9;
}

.currentPhoto {
    width: 100%;
    object-fit: contain;
    height: 100%;
}

.thumbnail {
    max-height: 40vh;
}

.textArea {
    border: 1px solid rgb(199, 199, 199);
    width: 100%;
    min-height: 30vh;
}

.tox:not([dir="rtl"]) .tox-statusbar__branding {
    line-height: 5vh;
}

.tox:not([dir="rtl"]) .tox-statusbar__path > * {
    line-height: 5vh;
}
.Header {
    top: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    left: 0 !important;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: lighter;
    border-bottom: 1px solid rgb(50, 50, 50);
    z-index: 100000;
    background-color: rgb(50, 50, 50);
    color: #FFF !important;
}

.headerItem {
    /* max-width: 20vw !important; */
    min-height: 10vh !important;
    line-height: 10vh !important;
}


.headerLink, .headerLink:hover {
    color: #FFF !important;
    /* font-weight: bold !important; */
}

.headerLink::after {
    color: rgb(50, 50, 50);
}

.copyrightItem {
    color: #333 !important;
    cursor: context-menu;
    position: fixed;
    right: 0 !important;
}

#logoItem {
    width: 100px;
}

#LamaithLogo {
    width:  100%;
    height: 100% ;
    object-fit: fill;
}

.ant-menu {    
    background-color: rgb(50, 50, 50);
    color: white !important;
}

.ant-menu-submenu-popup > .ant-menu {
    background-color: rgb(50, 50, 50);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(50, 50, 50);
  }
.footer {
    background-color: #939597;
    border-top: 1px solid white;
}

.footerLeft {
}

.footerMiddle {
    border-right: 1px solid white !important;
    border-left: 1px solid white !important;
}

.copyRightItem {
    display:flex;
    flex-direction:row;
}

.footerMenu {
    padding-top: 5%;
    margin-bottom: 10%;
    margin-top: 5%;
    padding-left: 10%;
    background-color: #939597;
}

.footerRightMenu {
    margin-top: -5%;
}

.footerItem {
    max-width: 32vw !important;
    font-size: 12px;
}

.footerLink {
    font-size: 2vmin;
    color: white !important;
}

.footerLink:hover {
    color: #333 !important;
}

.footerSubmenuLink {
    color: white !important;
}

.ant-menu-submenu-title {
    color: white !important;
}

.ant-menu-submenu-title:hover {
    color: #333 !important;
}

.ant-menu-item-group-title {
    color: rgb(163, 163, 163) !important;
}


.adminItem {
    font-style: italic;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
}

.copyrightIcon {
    color: white;
}

.copyrightText {
    font-size: 2vmin;
    color: white;
    margin-top: 10%;
    margin-left: 10%;
    padding-right: 10%;
}

.footerSubmenu {
    font-size: 2vmin;
    color: white;
}

/* .ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  height: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 13px;
  text-overflow: ellipsis;
} */
html body div#root div.footer.container-fluid div.row div.footerLeft.col div.row ul.ant-menu.ant-menu-light.footerMenu.ant-menu-root.ant-menu-vertical li.ant-menu-item.ant-menu-item-only-child.footerItem,
html body div#root div.footer.container-fluid div.row div.footerRight.col div.footerRightMenu.row ul.ant-menu.ant-menu-light.footerMenu.ant-menu-root.ant-menu-vertical li.ant-menu-item.ant-menu-item-only-child.footerItem {
    height: 20px;
    margin-top: 2px;
    margin-bottom: 1px;
    padding: 0 16px;
    overflow: hidden;
    line-height: 13px;
    text-overflow: ellipsis;
}

.paymentOptionLogo {
    height: 25%;
    width: 25%;
}

.paymentOptionsRow {
    width: 80%;
    height: 10%;
    margin-left: 5%;
}

.contactDetailsHeading {
    margin-top: 5%;
    font-weight: bold;
    color: white;
    font-size: 3vmin;
}

.contactDetails {
    margin-top: -4%;
    color: white;
    font-size: 2vmin;
}

.contactDetailsRow {
    margin-left: 10%;
}

.footerIllustration {
    padding-top: 5%;
    padding-bottom: 5%;
    height: 90%;
    width: 90%;
    margin-left: 7%;
}

.socialMediaRow {
    margin-top: -7%;
}

.socialMediaHeading {
    margin-left: 10%;
    padding: 0 16px;
    font-size: 2vmin;
    font-weight: bold;
    color: white;
}

.socialMediaIconsRow {
    padding: 0 16px;
    margin-top: -2%;
    margin-left: 8.3%;
}

.socialLogo {
    height: 3vmax;
    width: 3vmax;
    margin-right: 15px;
}
.contactUsIllustration {
    width: 90%;
    height: 90%;
    margin-bottom: 50px;
}

.contactMessage {
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
}
.home, .back {
    border: 1px solid #d9d9d9 !important;
    padding: 5px 5px 5px 5px !important;
    margin-left: 1vw !important;
    transition: 0.3s !important;
}

.home, .back {
    border: 1px solid #40A9FF !important;
    color: 1px solid #40A9FF !important;
}
.link {
    color: #333;
    border: 1px solid #d9d9d9;
    padding: 5px 5px 5px 5px;
}

.link:hover {
    border: 1px solid #40A9FF;
}
.link {
    color: #333;
    border: 1px solid #d9d9d9;
    padding: 5px 5px 5px 5px;
}

#back, #addProduct {
    margin-right: 1vw;
}

.productBrief {
    border: none !important;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    border-bottom: 1px solid #d9d9d9 !important;
}

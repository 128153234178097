@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

.collectionGrid {
    display: grid;
    grid-template-columns: 20vmax 20vmax 20vmax 20vmax 20vmax;
    word-break: break-word;
}

.collectionBrief {
    height: 20vmax;
    width: 20vmax;
    position: relative;
    border: 1px solid rgb(211, 211, 211);
}

.collectionThumbnail {
    height: 100%;
    width: 100%;
    object-fit: contain;
    transition: 0.3s;
}

.overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(34,34,34,0)), to(rgba(34,34,34,0.7)));
    background: linear-gradient(to bottom, rgba(34,34,34,0), rgba(34,34,34,0.7));
    -webkit-transition: opacity .3s linear;
    transition: opacity 0.3s linear;
    font-family: 'Josefin Sans', sans-serif;
}

.overlay:hover {
    opacity: 0.9;
}

.overlayInner {
    position: absolute;
    padding: 1.3125rem 2rem;
    bottom: 0;
    left: 0;
    width: 100%;
}

.collectionBriefName {
    font-size: 2vmax;
    font-weight: 600;
    color: white;
    line-height: 1.5rem;
    overflow-wrap: break-word;
    position: absolute;
    bottom: 18px;
    left: 18px;
    font-family: 'Open Sans', sans-serif !important;
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');

.diary {
    margin: auto;
    width: 85vw;
    border: 1px solid rgb(185, 185, 185);
    background-color:rgb(252, 252, 252);
}

.entry {
    border-top: 1px solid rgb(185, 185, 185);
    margin: 1vh 1vw 1vh 1vw;
}

.logoContainer {
    margin: auto;
    width: 20vw;
    height: 20vh;
    padding-bottom: 1vh;
    margin-bottom: 2vh;
}

.logo {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.entryTitle {
    margin: auto;
    margin-top: 1vh;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 4vmax;
}

.entryDate {
    margin: auto;
    text-align: center;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
}

p {
    font-family: 'Open Sans', sans-serif;
}

.entryPhotoDiv {
    height: 20vmax;
    width: 20vmax;
    border: 1px solid rgb(185, 185, 185);
}

.entryPhotoCol {
    width: 30%;
}

.entryDescriptionCol {
    max-height: 20vmax;
    margin-left: 21vmax;
    margin-top: -20vmax;
}

.entryPhoto {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.entryBrief {
    border-top: 1px solid rgb(185, 185, 185);
    width: 90%;
    height: 25vmax;
    overflow: hidden;
    margin-top: 2vh;
}

.entryBriefInner {
    width: 100%;
    height: 25vmax;
    margin-top: 2vh;

}

.entryDescriptionRow {
    max-height: 15vmax;
    display: inline-block;
    width: calc(100%);
    font-size: 13px;
    overflow: hidden;
}

.entryDescription {
}

.seeMoreAndDateRow {
    font-weight: bold;

}
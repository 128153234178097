.footer {
    background-color: #939597;
    border-top: 1px solid white;
}

.footerLeft {
}

.footerMiddle {
    border-right: 1px solid white !important;
    border-left: 1px solid white !important;
}

.copyRightItem {
    display:flex;
    flex-direction:row;
}

.footerMenu {
    padding-top: 5%;
    margin-bottom: 10%;
    margin-top: 5%;
    padding-left: 10%;
    background-color: #939597;
}

.footerRightMenu {
    margin-top: -5%;
}

.footerItem {
    max-width: 32vw !important;
    font-size: 12px;
}

.footerLink {
    font-size: 2vmin;
    color: white !important;
}

.footerLink:hover {
    color: #333 !important;
}

.footerSubmenuLink {
    color: white !important;
}

.ant-menu-submenu-title {
    color: white !important;
}

.ant-menu-submenu-title:hover {
    color: #333 !important;
}

.ant-menu-item-group-title {
    color: rgb(163, 163, 163) !important;
}


.adminItem {
    font-style: italic;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
}

.copyrightIcon {
    color: white;
}

.copyrightText {
    font-size: 2vmin;
    color: white;
    margin-top: 10%;
    margin-left: 10%;
    padding-right: 10%;
}

.footerSubmenu {
    font-size: 2vmin;
    color: white;
}

/* .ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  height: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 13px;
  text-overflow: ellipsis;
} */
html body div#root div.footer.container-fluid div.row div.footerLeft.col div.row ul.ant-menu.ant-menu-light.footerMenu.ant-menu-root.ant-menu-vertical li.ant-menu-item.ant-menu-item-only-child.footerItem,
html body div#root div.footer.container-fluid div.row div.footerRight.col div.footerRightMenu.row ul.ant-menu.ant-menu-light.footerMenu.ant-menu-root.ant-menu-vertical li.ant-menu-item.ant-menu-item-only-child.footerItem {
    height: 20px;
    margin-top: 2px;
    margin-bottom: 1px;
    padding: 0 16px;
    overflow: hidden;
    line-height: 13px;
    text-overflow: ellipsis;
}

.paymentOptionLogo {
    height: 25%;
    width: 25%;
}

.paymentOptionsRow {
    width: 80%;
    height: 10%;
    margin-left: 5%;
}

.contactDetailsHeading {
    margin-top: 5%;
    font-weight: bold;
    color: white;
    font-size: 3vmin;
}

.contactDetails {
    margin-top: -4%;
    color: white;
    font-size: 2vmin;
}

.contactDetailsRow {
    margin-left: 10%;
}

.footerIllustration {
    padding-top: 5%;
    padding-bottom: 5%;
    height: 90%;
    width: 90%;
    margin-left: 7%;
}

.socialMediaRow {
    margin-top: -7%;
}

.socialMediaHeading {
    margin-left: 10%;
    padding: 0 16px;
    font-size: 2vmin;
    font-weight: bold;
    color: white;
}

.socialMediaIconsRow {
    padding: 0 16px;
    margin-top: -2%;
    margin-left: 8.3%;
}

.socialLogo {
    height: 3vmax;
    width: 3vmax;
    margin-right: 15px;
}
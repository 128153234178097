.Home  {
    background-color: 'black';
    margin-top: -10px;
    background-image: url("../home-illustration-nov-2021.png") !important;
    height:100vh;
    width:100vw;
    background-size: cover;
}

@media screen and (max-width: 600px) {
    .Home  {
        background-color: 'black';
        margin-top: -10px;
        background-image: url("../home-illustration-mobile.png") !important;
        height:100vh;
        width:100vw;
        background-size: cover;
    }
  }
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');

.Header {
    top: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    left: 0 !important;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: lighter;
    border-bottom: 1px solid rgb(50, 50, 50);
    z-index: 100000;
    background-color: rgb(50, 50, 50);
    color: #FFF !important;
}

.headerItem {
    /* max-width: 20vw !important; */
    min-height: 10vh !important;
    line-height: 10vh !important;
}


.headerLink, .headerLink:hover {
    color: #FFF !important;
    /* font-weight: bold !important; */
}

.headerLink::after {
    color: rgb(50, 50, 50);
}

.copyrightItem {
    color: #333 !important;
    cursor: context-menu;
    position: fixed;
    right: 0 !important;
}

#logoItem {
    width: 100px;
}

#LamaithLogo {
    width:  100%;
    height: 100% ;
    object-fit: fill;
}

.ant-menu {    
    background-color: rgb(50, 50, 50);
    color: white !important;
}

.ant-menu-submenu-popup > .ant-menu {
    background-color: rgb(50, 50, 50);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(50, 50, 50);
  }
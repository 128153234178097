.contactUsIllustration {
    width: 90%;
    height: 90%;
    margin-bottom: 50px;
}

.contactMessage {
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
}
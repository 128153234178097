@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');


#descriptionDiv {
    width: 25vw;
    height: 45vw;
}

#descriptionPic {
    width: 100%;
    height: 100%;
}

#photo0div {
    width: 55vw;
    height: 50vw;
    margin-left: -4%;
}

#photo1div {
    margin-top: 5vw;
    height: 70vw;
}

#photo2div {
    width: 55vw;
    height: 80vw;
}

#photo3div {
}

#photo4div {
    margin-top: 10vh;
    border-radius: 50%;
    transform: rotate(30deg);
    width: 30vw;
    margin-left: 5vw;
}

#photo4 {
    border-radius: 50%;
}

#photo5div {
    margin-top: 5vw;
    height: 70vw;
}

#photo6div {
    width: 55vw;
    height: 80vw;
}

#photo7div {
}

#photo8div {
}

#photo9div {
}

.photoDiv {
    position: relative;
    border: 1px solid #D9D9D9;
    margin-bottom: 10vh;
}

.photoRow6 {
    display: grid;
    grid-template-columns: 45vw 45vw ;
    word-break: break-word;
}

.photo {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap');

.cardRow {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
}

.logoRow {
    position: relative;
    margin: auto auto 10vh auto;
    width: 100%;
    height: 30vh;
}

.lamaithLogo {
    height: 100%;
    width: 100%;
}

.menuCard {
    width: 100%;
    height: 100%;
    border: 1px solid #333;
    transition: 0.5s;
}

.menuCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.cardImage {
    height: 100%;
    width: 100%;
    transition: 1s;
}

.cardImage:hover {
    opacity: 0.9
}

.navLink {
    height: 15vmax;
    width: 15vmax;
    margin: auto;
    font-size: 1.5vmax;
    position: relative;
    font-family:'Inter', Tahoma, Geneva, Verdana, sans-serif;
    color : #333;
    text-decoration: none !important;
}

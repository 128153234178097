@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');

.contentHeadingContainer {
    font-family: 'Josefin Sans', sans-serif !important;
    position: relative;
    font-size: 4vmax;
    color: #333;
    border-bottom: 1px solid #D9D9D9;
    width: 95%;
    margin-left: 2.5%;
}

.contentHeading {
    font-size: 1.5vmax;
}

.contentContainer {
    width: 95%;
    margin-top: 5vh;
    margin-bottom: 10vh;
    font-family: 'Roboto', sans-serif;
    margin-left: 2vw;
    margin-right: 2vw;
    background-color: #efeee9;

}

.content {
    font-family: 'Roboto', sans-serif;
}

.subHeading {
    font-family: 'Open Sans', sans-serif;
    font-weight: bolder;
    font-size: 1vmax;
    margin-bottom: 2vh;
}

.section {
    margin-bottom: 5vh;
}



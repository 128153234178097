@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');

*, *:before, *:after {
    box-sizing: border-box;
}

label {
    font-family: 'Open Sans', sans-serif;
    font-size: 2vmax;
}

input {
    font-family: 'Open Sans', sans-serif;
    border: none;
    border-bottom: 1px solid #D9D9D9;
    min-width: 100%;
}

input:focus {
    outline: none;
    /*border-bottom: 1px solid rgb(129, 126, 126) !important;*/
    border-bottom: 1px solid  rgb(45, 118, 212);
}

.error {
    font-style: italic;
    color: rgb(172, 20, 20);
    margin-bottom: 1vh;
}

.MyTextInputContainer {
    margin-bottom: 10px;

}

button {
    border: 1px solid #D9D9D9;
    margin-top: 2vh;
    border-radius: 1px;
    outline: none !important;
    color: #333;
    background-color: #FFF;
    transition: 0.2s;
    height: 5vh !important;
}

button:hover {
    cursor: pointer
}

button[type='submit']:hover {
    cursor: pointer;
    border: 1px solid #40A9FF;
    color: #40A9FF;
}


.photoField {
    margin: 1.5vh 0 1.5vh 0;
}

.addButton {
    margin: 2.5vh 0 1.5vh 0;
    border: 1px solid #D9D9D9;
}

.addButton:hover {
    border: 1px solid rgb(45, 118, 212);
    background-color:  rgb(231, 240, 252);
}

.removeButton {
    margin-top: 2vh;
    color: white;
    background-color: rgb(238, 53, 53);
    border: 1px solid rgb(218, 64, 64);;
}

.removeButton:hover {
    background-color: rgb(218, 64, 64);
    border: 1px solid rgb(218, 64, 64);;
}

.deleteButton {
    margin-top: 2vh;
    height: 5vh;
    width: 100%;
    border-radius: 5px;
    color: white;
    background-color: rgb(238, 53, 53);
    border: 1px solid rgb(218, 64, 64);;
}

.deleteButton:hover {
    background-color: rgb(218, 64, 64);
    border: 1px solid rgb(218, 64, 64);;
}

.currentPhotoContainer {
    width: 40vw;
    height: 40vh;
    border: 1px solid #D9D9D9;
}

.currentPhoto {
    width: 100%;
    object-fit: contain;
    height: 100%;
}

.thumbnail {
    max-height: 40vh;
}

.textArea {
    border: 1px solid rgb(199, 199, 199);
    width: 100%;
    min-height: 30vh;
}

.tox:not([dir="rtl"]) .tox-statusbar__branding {
    line-height: 5vh;
}

.tox:not([dir="rtl"]) .tox-statusbar__path > * {
    line-height: 5vh;
}
html {
    background-color: #efeee9;
}
.App {
    min-height: 100vh;
    padding-top: 80px;
    background-color: #efeee9;
}

body {
    /* white-space: pre-line; */
    min-height: 100%;
    background-color: #efeee9;
}

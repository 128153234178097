@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');

.service {
    margin-top: 2vh;
    margin-bottom: 2vh;
    border-bottom: 1px solid rgb(223, 223, 223);
}
.serviceName {
    font-size: 1.2vmax;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}
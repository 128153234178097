.link {
    color: #333;
    border: 1px solid #d9d9d9;
    padding: 5px 5px 5px 5px;
}

#back, #addProduct {
    margin-right: 1vw;
}

.productBrief {
    border: none !important;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    border-bottom: 1px solid #d9d9d9 !important;
}
.home, .back {
    border: 1px solid #d9d9d9 !important;
    padding: 5px 5px 5px 5px !important;
    margin-left: 1vw !important;
    transition: 0.3s !important;
}

.home, .back {
    border: 1px solid #40A9FF !important;
    color: 1px solid #40A9FF !important;
}